import React,{useEffect,useState} from "react";
// import reactCSS from 'reactcss'
import "./App.css";
import { ToastContainer } from "react-toastify";
import Router from "./routes";
import AudioPlayers from "./pages/AudioPlayers";
import ChatIcon from "./pages/ChatIcon";

import { BsFillChatDotsFill } from 'react-icons/bs';
import {  useLocation, useParams } from "react-router-dom";

export default function MainApp() {
  // const [RemoveIcon, setRemoveIcon] =  useState(true)
  const pathlocation = useLocation()
  console.log(pathlocation)
  const token  = JSON.parse(localStorage.getItem("accessToken"))
  const guestlogin  = JSON.parse(localStorage.getItem("guestlogin"))
  const Location = localStorage.getItem("locationpath")
  const [oldlocation,setoldlocation]=useState("/")
  const [locationstatus,setlocationstatus]=useState("/")
  const [ChatIconDel, setChatIconDel] = useState(false)
  const [LoginGuest, setLoginGuest] = useState(false)
  
  // useEffect(()=>{
  //  if(pathlocation.pathname === "/Login" || pathlocation.pathname === "/Register" || pathlocation.pathname === "/Chatting" ){
  //   setRemoveIcon(false)
  //  }else{
  //   setRemoveIcon(true)
  //  }
  // },[pathlocation])
  // console.log(RemoveIcon,"remove")

  //  window.location.reload(true)
  const data= (location)=>{
    localStorage.setItem("locationpath",location)
    setoldlocation(location)
    // window.location.reload(false)
  }
  useEffect(()=>{
    setlocationstatus(oldlocation)
  },[oldlocation])

  useEffect(()=>{
    if(token===null || token===undefined){
      setChatIconDel(true)
    }else{
      setChatIconDel(false)
    }
  },[token])

  useEffect(()=>{
    if(guestlogin===null || guestlogin===undefined || guestlogin===""){
      setLoginGuest(true)
    }else{
      setLoginGuest(false)
    }
  },[guestlogin])
  return (
    <>
     
     {/* <ChatIcon/> */}
    {/* <a href="/Chatting" target="_blank">
   {LoginGuest  ? <div className="icon-hide-chat" style={{display:"none", visibility:"hidden"}}></div> : <><BsFillChatDotsFill className="chaticon-mobile"/></> }
    </a> */}
        {(locationstatus==='/')?<>
     <AudioPlayers props={data}/>
      <Router />
       </>:<>
       <Router />
      <AudioPlayers props={data}/></>}
      <ToastContainer/>
    </>
  );
}

