import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import HeaderBottamSection from "../header/HeaderBottamSection";
import FooterSection from "../layout/FooterSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export default function Stories() {
  const { promiseInProgress } = usePromiseTracker();
  //ye recent story hai
  const [Recent , setRecent] = useState([])
  const [RecentselectedPage , setRecentselectedPage] = useState(1)
  const [RecentperPage , setRecentperPage] = useState(10)
  const [RecentData , setRecentData] = useState([])
  localStorage.setItem("RecentselectedPage",RecentselectedPage )
//ye more story hai
  const [StoriesViewVideo, setStoriesViewVideo] = useState([]);
  const [perPage, setperPage] = useState(16);
  const [selectedPage, setselectedPage] = useState(2);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [Count, setCount] = useState(0);
  localStorage.setItem("pageNumber",selectedPage )
  
  // console.log("selectedPage---",selectedPage)
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      768: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };
useEffect(()=>{
  axios
  .post(`https://app.soundchatradio.com:3000/api/v1/auth/listtimeline`, {
    page: 1,
    limit: 10000,
  })
  .then((response) => {
    setCount(response.data.data.rows.length);
  })
  .catch((error) => {
    console.log(error);
  })
},[])
  useEffect(()=>{

    axios
    .post(`https://app.soundchatradio.com:3000/api/v1/auth/listtimeline`, {
      page: RecentselectedPage,
      limit: RecentperPage,
    }) .then((response) => {
      setRecent(response.data.data.rows);
       let Recent =response.data.data.rows
       let recentdata =[]
      for (let i = 0; i < Recent.length; i++) {
        recentdata.push( {
          id:Recent[i].id,
          timeline: Recent[i].timelinemedia[0],
          timelinelikes :Recent[i].timelinelikes,
          timelinecomments :Recent[i].timelinecomments,
          desc: Recent[i].post_desc,
          time: Recent[i].createdAt,
          count: Recent[i].timelinemedia.length - 1,
          timeLineAllObject :Recent[i].timelinemedia
        })
      }
      setRecentData(recentdata)
    })
    .catch((error) => {
      console.log(error);
    })
  },[RecentselectedPage])
  useEffect(() => {
    trackPromise(
      axios
        .post(`https://app.soundchatradio.com:3000/api/v1/auth/listtimeline`, {
          page: selectedPage,
          limit: perPage,
        })
        .then((response) => {
          // setCount(response.data.data.count);
          let morestoryinner = []
        for (let i = 0; i < response.data.data.rows.length; i++) {
          morestoryinner.push({
            id:response.data.data.rows[i].id,
            timelinelikes :response.data.data.rows[i].timelinelikes,
            timelinecomments :response.data.data.rows[i].timelinecomments,
            timeline: response.data.data.rows[i].timelinemedia[0],
            desc: response.data.data.rows[i].post_desc,
            time: response.data.data.rows[i].createdAt,
            count: response.data.data.rows[i].timelinemedia.length - 1,
            timeLineAllObject : response.data.data.rows[i].timelinemedia
        })
        setStoriesViewVideo(morestoryinner)
      }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }, [perPage]);

  console.log(StoriesViewVideo,"data")
  const handlePageClick = (e) => {
    const selected = e.selected+2;
    console.log("selected-----", selected)
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/listtimeline`, {
        page: selected,
        limit: perPage,
      })
      .then((response) => {
        // setCount(response.data.data.count);
        let morestoryinner = []
        for (let i = 0; i < response.data.data.rows.length; i++) {
          morestoryinner.push({
            id:response.data.data.rows[i].id,
            timelinelikes :response.data.data.rows[i].timelinelikes,
            timelinecomments :response.data.data.rows[i].timelinecomments,
            timeline: response.data.data.rows[i].timelinemedia[0],
            desc: response.data.data.rows[i].post_desc,
            time: response.data.data.rows[i].createdAt,
            count: response.data.data.rows[i].timelinemedia.length - 1,
            timeLineAllObject : response.data.data.rows[i].timelinemedia
        })
        setStoriesViewVideo(morestoryinner)
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setselectedPage(selected);
    setOffset(selectedPage + 1);
    // localStorage.setItem("pageNumber",selectedPage + 1 )
  };
  
//  console.log("offset---", offset)
  useEffect(() => {
    setPageCount((Math.ceil(Count / perPage))-1);
  }, [Count]);
  console.log("")

 return (
    <>
      <HeaderBottamSection />
      <section className="bg-color">
        {promiseInProgress === true ? (
          <div
            className="spinner"
            style={{
              display: "flex",
              justifyContent: "center",
              alignIitems: "center",
              height: "300px",
            }}
          >
            <ThreeDots
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="container-fluid">
              <div className="news-heading bg-div-color">
                <h5>RECENT STORIES</h5>
              </div>
              {(RecentData.length>0)?<>
              <OwlCarousel
                items={4}
                className="owl-carousel owl-theme "
                autoplay={false}
                dots={false}
                touchDrag={true}
                lazyLoad={true}
                responsive={state.responsive}
                margin={20}
              >
                {(RecentData.length>0)? <>{RecentData.length && RecentData.map((element, index) => { 
                  return (
                    <div key={index}>
                      {element.timeline.media_type === "image" ? (
                        <section className="showcase">
                          <div className="day-div">
                            <p>{moment(element.time).format("ddd, ll")}</p>
                          </div>
                          <div className="count-div">
                                {element.count > 0 ? (
                                  <p>+{element.count}</p>
                                ) : (
                                  <></>
                                )}
                            </div>
                          <img
                            src={
                              "https://app.soundchatradio.com/soundradiobackend/images/timeline/" +
                              element.timeline.media_name
                            }
                            alt="err_img"
                          />
                          <div className="overlay-div">
                            <h2>{element.desc}</h2>
                            <NavLink
                              key={"/MoreViewStory"}
                              to="/MoreViewStory"
                              state={{
                                currentvideo: element,
                                related: StoriesViewVideo,
                              }}
                            >
                              <p>View Story</p>
                            </NavLink>
                          </div>
                        </section>
                      ) : element.timeline.media_type === "video" ? (
                        <section className="showcase" key={index}>
                          <div className="day-div">
                            <p>{moment(element.time).format("ddd, ll")}</p>
                          </div>
                          <div className="count-div">
                                {element.count > 0 ? (
                                  <p>+{element.count}</p>
                                ) : (
                                  <></>
                                )}
                            </div>
                          <img
                            src={
                              "https://app.soundchatradio.com/soundradiobackend/images/thumbnail/" +
                              element.timeline.media_thumbnail
                            }
                            alt="err_img_video"
                          />
                          <div className="news-video-icn">
                            <BsFillCaretRightFill />
                          </div>
                          <div className="overlay-div">
                            <h2>{element.desc}</h2>
                            <NavLink
                              key={"/MoreViewStory"}
                              to="/MoreViewStory"
                              state={{
                                currentvideo: element,
                                related: StoriesViewVideo,
                              }}
                            >
                              <p>View Story</p>
                            </NavLink>
                          </div>
                        </section>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
                </>:<></>}
              </OwlCarousel>
              </>:<></>}
            </div>
            <div className="container-fluid">
              <div className="news-heading bg-div-color">
                <h5>MORE STORIES</h5>
              </div>
              <Row>
                {StoriesViewVideo.map((element, index) => {
                  return (
                    
                      <Col key={index} sm={12} md={6} lg={3} className="mb-4">
                        {element.timeline.media_type === "image" ? (
                          <section className="showcase" key={element.id}>
                            <div className="day-div ">
                              <p>{moment(element.time).format("ddd, ll")}</p>
                            </div>
                            
                              <div className="count-div">
                                {element.count > 0 ? (
                                  <p>+{element.count}</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            

                            <img
                              src={
                                "https://app.soundchatradio.com/soundradiobackend/images/timeline/" +
                                element.timeline.media_name
                              }
                              alt="err_img"
                            />
                            <div className="overlay-div">
                              <h2>{element.desc}</h2>
                              <NavLink
                                key={"/Viewstory"}
                                to="/Viewstory"
                                state={{
                                  currentvideo: element,
                                  related: StoriesViewVideo,
                                }}
                              >
                                <p>View Story</p>
                              </NavLink>
                            </div>
                          </section>
                        ) : element.timeline.media_type === "video" ? (
                          <section className="showcase" key={index}>
                            <div className="day-div">
                              <p>{moment(element.time).format("ddd, ll")}</p>
                            </div>
                              <div className="count-div">
                                {element.count > 0 ? (
                                  <p>+{element.count}</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            <img
                              src={
                                "https://app.soundchatradio.com/soundradiobackend/images/thumbnail/" +
                                element.timeline.media_thumbnail
                              }
                              alt="err_img_video"
                            />
                            <div className="news-video-icn">
                              <BsFillCaretRightFill />
                            </div>
                            <div className="overlay-div">
                              <h2>{element.desc}</h2>
                              <NavLink
                                key={"/Viewstory"}
                                to="/Viewstory"
                                state={{
                                  currentvideo: element,
                                  related: StoriesViewVideo,
                                }}
                              >
                                <p>View Story</p>
                              </NavLink>
                            </div>
                          </section>
                        ) : (
                          <></>
                        )}
                      </Col>
                    
                  );
                })}
              </Row>
            </div>
          </>
        )}
         <div className="media-pagination">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={(e)=>{handlePageClick(e)}}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
      </section>
      <FooterSection />
    </>
  );
}
