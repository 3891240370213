import React, { useState } from "react";
import { FiUser } from "react-icons/fi";
import axios from "axios";
import ChattingLogin from "./ChattingLogin";
import ChattingLoginGuest from "./ChattingLoginGuest";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import logo from "../asstes/images/logo.png";
export default function Chatting() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [guestlogin, setguestlogin] = useState(
    localStorage.getItem("guestlogin")
  );

  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  function handleSubmit(values) {
    axios({
      method: "POST",
      url: "https://app.soundchatradio.com:3000/api/v1/auth/guestsignin",
      data: {
        username: values.username,
      },
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === 400) {
          showToastMessageone(response.data.message);
        } else {
          showToastMessage("Login Guests Login Successfully ");
          setguestlogin(response.data.data.username);
          localStorage.setItem(
            "guestlogin",
            JSON.stringify(response.data.data.username)
          );
          navigate("/Chatting", { replace: true });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });
  return (
    
    <div className="chating-login-bg">
      <div className="">
      <div className={(token || guestlogin) ? "chating-login-box chattingpage":"chating-login-box"}>
      <div className="login-with-chat">
      
        {!token ? (
          <>
            {guestlogin === undefined ||
            guestlogin === "" ||
            guestlogin === null ? (
              <>
              <img src={logo} className="img-fluid" alt="err" />
                <NavLink
                className="chatting-login-bt"
                  key={"/Login"}
                  to="/Login"
                  state={{ callbackurl: "/Chatting" }}
                >
                  Login to chat
                </NavLink>
                
                <p className="div-or-chating">or</p>
              </>
            ) : (
              <></>
            )}
            {guestlogin === undefined ||
            guestlogin === "" ||
            guestlogin === null ? (
              <>
                <form className="form-group" onSubmit={formik.handleSubmit}>
                  <div className="form-group has-search-chating">
                    <span className="user-icon-div-chating">
                      <FiUser />
                    </span>
                    <input
                      placeholder="Enter Guest Username"
                      type="text"
                      name="username"
                      autoComplete="off"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.username && formik.touched.username && (
                      <p className="errormessage mt-3">
                        {formik.errors.username}
                      </p>
                    )}
                    <button
                      type="submit"
                      className="chatting-login-bt new-user-chat-010"
                    >
                      Login as Guest
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <ChattingLoginGuest />
              </>
            )}
          </>
        ) : (
          <>
            <ChattingLogin />
          </>
        )}
      </div>
      </div>
    </div>
    </div>
  );
}






