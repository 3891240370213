import React,{useEffect} from 'react'
import FooterSection from '../compontes/layout/FooterSection'
// import InterviewSection from '../compontes/layout/InterviewSection'
import NewsSection from '../compontes/layout/NewsSection'
// import PodcastSection from '../compontes/layout/PodcastSection'
import SoundchatVideoSection from '../compontes/layout/SoundchatVideoSection'
import HeaderTopSection from '../compontes/header/HeaderTopSection';
import HeaderBottamSection from '../compontes/header/HeaderBottamSection'
import { useState,Suspense  } from 'react';
import MobileAdsHeader from './MobileAdsHeader';
const InterviewSection = React.lazy(() => import("../compontes/layout/InterviewSection"));

export default function Home() {
  const [audioplayerstatus,setaudioplayerstatus]=useState("pause")


  function callbackheader(status){
    setaudioplayerstatus(status)
    // console.log(status,'home')
  }
  return (
    <>
    <HeaderTopSection callback={callbackheader}/>
    <HeaderBottamSection />
    {/* <div className="header-top-mobile">
      <div className="header-top-wrapper d-flex">
        <div className="header-section-one">
          <div className="video-section">
            <video className="video-div" autoPlay={true} loop muted>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="header-section-tow">
          <div className="d-flex right-div-section">
            <div className="logo-soundchat">
              <img
                src={
                  "https://app.soundchatradio.com/soundradiobackend/images/podcast/" +
                  ShowTimeData.show_image
                }
                alt="img-error"
              />
            </div>
            <div className="logo-text-heading">
              <h6 className="header-new-bottom-new">
                {ShowTimeData.show_name}
              </h6>
              <div className="top-head-section">
                <p>{day}</p>
                <p className="todu">
                  {ShowTimeData.show_start_date}-{ShowTimeData.show_end_date}
                </p>
              </div>
              <div className="main-audio-live-content">
                <AudioPlayer
                  src={LiveAudio.livelink}
                  autoPlay={Boolean(audioStatus)}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  showJumpControls={false}
                  showFilledProgress={false}
                  onPause={(e) => {
                    console.log("onPlay");
                    props.callback("pause");
                    localStorage.setItem("audioplayer", false);
                    localStorage.setItem("videoplayer", true);
                    if (waveClass === "box active") {
                      setwaveClass("box");
                    }
                  }}
                  onPlay={(e) => {
                    props.callback("play");
                    localStorage.setItem("audioplayer", true);
                    localStorage.setItem("videoplayer", false);

                    console.log("onPlay");
                    if (waveClass === "box") {
                      setwaveClass("box active");
                    }
                  }}
                />
                <div className="box-container flex">
                  <div className={`${waveClass} box1`}></div>
                  <div className={`${waveClass} box2`}></div>
                  <div className={`${waveClass} box3`}></div>
                  <div className={`${waveClass} box4`}></div>
                  <div className={`${waveClass} box5`}></div>
                  <div className={`${waveClass} box6`}></div>
                  <div className={`${waveClass} box7`}></div>
                  <div className={`${waveClass} box2`}></div>
                  <div className={`${waveClass} box3`}></div>
                  <div className={`${waveClass} box4`}></div>
                  <div className={`${waveClass} box5`}></div>
                  <div className={`${waveClass} box6`}></div>
                  <div className={`${waveClass} box7`}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <MobileAdsHeader/>
    <SoundchatVideoSection props={audioplayerstatus}/>
    {/* <PodcastSection/> */}
    <NewsSection/>
    <Suspense fallback={<div>Loading</div>}>
    <InterviewSection/>
			</Suspense>
    <FooterSection/>
    </>
  )
}




