import React, { useState, useEffect } from "react";
import HeaderBottamSection from "../compontes/header/HeaderBottamSection";
import FooterSection from "../compontes/layout/FooterSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";
import axios from "axios";
export default function Watch() {
  const [LiveVedio, setLiveVedio] = useState({});
  useEffect(() => {
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/listlivecontent`)
      .then((response) => {
        setLiveVedio(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <HeaderBottamSection />
      <section className="bg-color">
        <div className="watch-section">
          <div className="container">
            <Row>
              <Col xs={12} md={6} lg={12}>
                <div className="video-soundchat">
                  <div className="watch-set">
                    <ReactPlayer
                      className="react-player-setc"
                      url={LiveVedio.livelink}
                      width="100%"
                      height="500px"
                      volume={1}
                      auto="true"
                      playing={true}
                      controls={true}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FooterSection />
    </>
  );
}
