import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
export default function GridImages({ imagesprops }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const photos = [];
  const photosgrid = [];


  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  for (let i = 0; i < imagesprops.length; i++) {
//     if(imagesprops.length===1){
//       rndInt = randomIntFromInterval(2, 2);
//       rndInt2 = randomIntFromInterval(1, 2);
//     }
    const rndInt = randomIntFromInterval(2, 2);
    const rndInt2 = randomIntFromInterval(1, 2);
    photos.push({
      src:
        "https://app.soundchatradio.com/soundradiobackend/images/timeline/" +
        imagesprops[i].media_name,
      width: rndInt,
      height: rndInt2,
    });
  }

  var  rndInt;
  var  rndInt2;
  
  for (let i = 0; i < (imagesprops.length>3? 3:imagesprops.length); i++) {
      if(imagesprops.length===1){
            rndInt = randomIntFromInterval(2, 2);
            rndInt2 = randomIntFromInterval(1, 2);
          }else if(imagesprops.length===2){
            rndInt = randomIntFromInterval(1, 3);
            rndInt2 = randomIntFromInterval(1, 2);
          }else{
            rndInt = randomIntFromInterval(4,2);
            rndInt2 = randomIntFromInterval(1,2);
          }
//     const rndInt = randomIntFromInterval(2, 2);
//     const rndInt2 = randomIntFromInterval(1, 2);
    photosgrid.push({
      src:
        "https://app.soundchatradio.com/soundradiobackend/images/timeline/" +
        imagesprops[i].media_name,
      width: rndInt,
      height: rndInt2,
    });
  }
  var remainingImage = imagesprops.length - 3;
  return (
    <section>
      <div className="gallery-container">
      {/* <span className="img-gallery-count">+{remainingImage}</span> */}
        {/* <div className="gallery-div"> */}
        <Gallery photos={photosgrid} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {/* </div> */}
      </div>
    </section>
  );
}
