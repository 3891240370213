// MoreViewStory
import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import moment from "moment-timezone";
import FbImageLibrary1 from "../FbImageLibrary1";
import GridImages from "../GridImages";

import { toast } from "react-toastify";
import "react-image-lightbox/style.css";
import HeaderBottamSection from "../../compontes/header/HeaderBottamSection";
// import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import FooterSection from "../../compontes/layout/FooterSection";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TiArrowForwardOutline } from "react-icons/ti";
import { MdMessage } from "react-icons/md";
import { BiDislike, BiLike } from "react-icons/bi";
import "react-comments-section/dist/index.css";
export default function MoreViewStory() {
  // const token = localStorage.getItem("accessToken");
  const focusPoint = useRef(null);
  const RecentselectedPage = localStorage.getItem("RecentselectedPage")
  
  // console.log(page,"ok")
  // const [a, setA] = useState(3)
  const user_id = localStorage.getItem("id");
  const data = useLocation();
  const [showText, setShowText] = useState(true);
  const [ButtonClickShow, setButtonClickShow] = useState(true);
  const [ReplyValue, setReplyValue] = useState("");
  const [Value, setValue] = useState("");
  const [MediaType, setMediaType] = useState({});
  const [RelatedVideo, setRelatedVideo] = useState({});
  const [AllTimeLineData, setAllTimeLineData] = useState([]);
  const [Comment, setComment] = useState([]);
  const [Like, setLike] = useState([]);
  const [LikesIconId, setLikesIconId] = useState("");
  const [IdCall, setIdCall] = useState();
  const [Timelinealldata, setTimelinealldata] = useState([]);
  const [Time, setTime] = useState();
  const [CommentListing, setCommentListing] = useState([]);
  const [AddAllComent, setAddAllComent] = useState([]);
  const [AddAllLike, setAddAllLike] = useState([]);
  const [ViewStoryState, setViewStoryState] = useState(false);
  const [CommentListingAllData, setCommentListingAllData] = useState([]);
  useEffect(() => {
    setRelatedVideo(data.state.currentvideo);
    setIdCall(data.state.currentvideo.id);
    setTime(data.state.currentvideo.createdAt);
    setLike(data.state.currentvideo.timelinelikes);
    setComment(data.state.currentvideo.timelinecomments);
    setTimelinealldata(data.state.currentvideo.timeLineAllObject);
    setAllTimeLineData(data.state.related);
    setMediaType(data.state.currentvideo.timeline);
  }, [data.state]);

  // useEffect(()=>{
  //   if(page > 3){
  //     setA(page)
  //   }
  // },[page])
  // console.log("a---", a)

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  function onClickReply (Id) {
     setShowText(Id);
     if (ButtonClickShow === false){
      setButtonClickShow(true);
     }
     else
     { 
      setButtonClickShow(false);
     }
  };

  // useEffect(() =>focusPoint.onClickReply(!showText? (setShowText(true)) : setShowText(false)))
      // 'click', () => alert('Hi'), { once: true }
    //   if (showText === false) setShowText(true);
    // else setShowText(false);
      
    
    


  useEffect(() => {
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/listtimeline`, {
        page: RecentselectedPage,
        limit: 10,
      })
      .then((response) => {
        let commentdatalist = [];
        for (let i = 0; i < response.data.data.rows.length; i++) {
          commentdatalist.push({
            timelinecomments: response.data.data.rows[i].timelinecomments,
            timelinelikes: response.data.data.rows[i].timelinelikes,
          });
        }
        setCommentListing(commentdatalist);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ViewStoryState]);

  //  console.log(CommentListing,"okkhh")
  useEffect(() => {
    for (let i = 0; i < CommentListing.length; i++) {
      if (CommentListing[i].timelinecomments.length > 0) {
        // console.log("inside",CommentListing[i].timelinecomments[0].id_timeline,IdCall)
        if (CommentListing[i].timelinecomments[0].id_timeline === IdCall) {
          setAddAllComent(CommentListing[i].timelinecomments);
        } else {
          console.log("invalid");
        }
      }
      if (CommentListing[i].timelinelikes.length > 0) {
        // console.log("inside",CommentListing[i].timelinecomments[0].id_timeline,IdCall)
        if (CommentListing[i].timelinelikes[0].id_timeline === IdCall) {
          setAddAllLike(CommentListing[i].timelinelikes, "likes");
        } else {
          console.log("invalid");
        }
      }
    }
  }, [CommentListing]);
  // console.log(AddAllComent, "comments final");

  useEffect(() => {
    let commentdata = [];
    for (let i = 0; i < AddAllComent.length; i++) {
      commentdata.push({
        text: AddAllComent[i].comments,
        createdAt: AddAllComent[i].createdAt,
        fullName: AddAllComent[i].user.fname,
        profilepic: AddAllComent[i].user.profilepic,
        comId: AddAllComent[i].id,
        replies: AddAllComent[i].timelinereplies,
      });
    }
    setCommentListingAllData(commentdata);
  }, [AddAllComent]);

  const increaseYourLike = () => {
    if (
      localStorage.getItem("accessToken") === undefined ||
      localStorage.getItem("accessToken") === "" ||
      localStorage.getItem("accessToken") === null
    ) {
      showToastMessageone("Login to like the post");
    } else {
      axios
        .post(
          "https://app.soundchatradio.com:3000/api/v1/auth/addtimelinelikes",
          {
            timeline_id: IdCall,
            likes_icon_id: "0",
            user_id: user_id,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("accessToken")),
            },
          }
        )
        .then((response) => {
          console.log(response);
          setViewStoryState(!ViewStoryState);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const decreaseYourLike = () => {
    if (
      localStorage.getItem("accessToken") === undefined ||
      localStorage.getItem("accessToken") === "" ||
      localStorage.getItem("accessToken") === null
    ) {
      showToastMessageone("Login to like the post");
    } else {
      axios
        .post(
          "https://app.soundchatradio.com:3000/api/v1/auth/addtimelinelikes",
          {
            timeline_id: IdCall,
            likes_icon_id: "1",
            user_id: user_id,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("accessToken")),
            },
          }
        )
        .then((response) => {
          console.log(response);
          setViewStoryState(!ViewStoryState);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function addComment() {
    if (
      localStorage.getItem("accessToken") === undefined ||
      localStorage.getItem("accessToken") === "" ||
      localStorage.getItem("accessToken") === null
    ) {
      showToastMessageone("Login to add comment to the post ");
    } else {
      axios
        .post(
          "https://app.soundchatradio.com:3000/api/v1/auth/addtimelinecomments",
          {
            timeline_id: IdCall,
            comments: Value,
            user_id: user_id,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("accessToken")),
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            showToastMessage(response.data.message);
            setViewStoryState(!ViewStoryState);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setValue("");
  }

  function replyComment(comId) {
    // console.log(comId, "ok")
    if (
      localStorage.getItem("accessToken") === undefined ||
      localStorage.getItem("accessToken") === "" ||
      localStorage.getItem("accessToken") === null
    ) {
      showToastMessageone("Login to add comment to the post ");
    } else {
      axios
        .post(
          "https://app.soundchatradio.com:3000/api/v1/auth/addtimelinereply",
          {
            id_comment: comId,
            reply: ReplyValue,
            user_id: user_id,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("accessToken")),
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            showToastMessage(response.data.message);
            setViewStoryState(!ViewStoryState);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setReplyValue("");
    }
  }

  // console.log(ReplyValue)
  return (
    <>
      <HeaderBottamSection />
      <section className="bg-color">
        <div>
          {MediaType.media_type !== undefined &&
          MediaType.media_type === "image" ? (
            <div className="story-page-container">
              <Row>
                <Col lg={8} md={6} sm={12}>
                <div className="story-view story-p-left">
                  {/* <div className="day-div-view-story">
                    <p>{moment(Time).format("ddd, ll")}</p>
                  </div> */}

                  <GridImages imagesprops={Timelinealldata} />
                  </div>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <div className="story-view story-p-right">
                    <div className="view-story-pra">
                      <p> {RelatedVideo.desc}</p>
                    </div>
                    <div className="view-icon-story">
                      <ul className="d-flex">
                        <li>
                          <TiArrowForwardOutline />
                        </li>
                        <li>
                          <BiLike onClick={increaseYourLike} />
                          <span className="like-comment">
                            {AddAllLike.length}
                          </span>
                        </li>
                        <li>
                          <BiDislike onClick={decreaseYourLike} />
                          <span className="like-comment">
                            {AddAllLike.length}
                          </span>
                        </li>
                        <li>
                          <MdMessage />
                          <span className="like-comment">
                            {CommentListingAllData.length}
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="overlay-div-comment">
                      <hr className="hr-style" />
                      <div className="scrolling-comment">
                      <div className="comment-box-com">
                        <form className="form">
                          <div className="userImg">
                            <img
                              className="imgdefault"
                              src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                              alt=""
                            />
                          </div>
                          <div className="emoji-input">
                            <input
                              type="text"
                              value={Value}
                              onChange={(e) => setValue(e.target.value)}
                              className="postComment"
                              placeholder="Type your reply here"
                            />
                          </div>
                          <span
                            type="submit"
                            onClick={addComment}
                            className="postBtn-div"
                          >
                            Post
                          </span>
                        </form>
                      </div>
                      
                      {CommentListingAllData.map((element) => {
                        return (
                          <div key={element.comId}>
                            {/* <p>{JSON.stringify(element.replies)}</p> */}
                            <div className="halfDiv-div" >
                              <div className="comment-list-img">
                                <img className="imgdefault-div" />
                              </div>
                              <div className="read-comment">
                                <h5>
                                  {element.fullName}
                                  <span className="comment-date">
                                    {moment(element.createdAt).format(
                                      "ddd, ll"
                                    )}
                                  </span>
                                </h5>
                                <p>{element.text}</p>
                              </div>
                            </div>

                            <div className="reply-comment">
                              <h5
                                className="reply-text"
                                ref={focusPoint}
                                onClick={() => {
                                  onClickReply(element.comId);
                                }}
                              >
                                Reply
                                {(element.replies.length>0)?<><span className="reply-count-div">{element.replies.length}&nbsp;replies</span></>:<><div style={{display:"none"}}></div></> }

                              </h5>
                              {(showText===element.comId && ButtonClickShow)? (
                                <>
                                  <div className="reply-comment-box">
                                    <div className="emoji-input-reply">
                                      <input
                                        type="text"
                                        value={ReplyValue}
                                        onChange={(e) =>
                                          setReplyValue(e.target.value)
                                        }
                                        className="postCommentreply"
                                        placeholder="Add a reply"
                                      />
                                    </div>
                                    <span
                                      type="submit"
                                      onClick={() => {
                                        replyComment(element.comId);
                                      }}
                                      className="postBtn-reply"
                                    >
                                      Post
                                    </span>
                                  </div>

                                  {element.replies.length > 0 ? (
                                    <>
                                      {element.replies.map((reply) => {
                                        return (
                                            <div key={reply.id} className="listing-reply-comment">
                                              <div className="listing-reply-img">
                                                <img
                                                  src={
                                                    "https://app.soundchatradio.com/soundradiobackend/images/" +
                                                    reply.user.profilepic
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="read-replycomment">
                                                <h5>
                                                  {reply.user.fname}
                                                  <span className="reply-date">
                                                    {moment(
                                                      reply.createdAt
                                                    ).format("ddd, ll")}
                                                  </span>{" "}
                                                </h5>
                                                <p>{reply.reply}</p>
                                              </div>
                                            </div>
                                          
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : MediaType.media_type !== undefined &&
            MediaType.media_type === "video" ? (
            <div className="story-page-container">
              <Row>
                <Col lg={8} md={6} sm={12}>
                  <div className="story-view story-p-left story-video">
                    {/* <div className="day-div-view-story">
                      <p>{moment(Time).format("ddd, ll")}</p>
                    </div> */}
                    <ReactPlayer
                      className="react-player"
                      url={
                        "https://app.soundchatradio.com/soundradiobackend/images/timeline/" +
                        RelatedVideo.timeline.media_name
                      }
                      width="100%"
                      height="500px"
                      volume={1}
                      auto="true"
                      playing={true}
                      controls={true}
                      light={
                        "https://app.soundchatradio.com/soundradiobackend/images/thumbnail/" +
                        RelatedVideo.timeline.media_thumbnail
                      }
                    />
                  </div>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <div className="story-view story-p-right">
                    <div className="view-story-pra">
                      <p> {RelatedVideo.desc}</p>
                    </div>
                    <div className="view-icon-story">
                      <ul className="d-flex">
                        <li>
                          <TiArrowForwardOutline />
                        </li>
                        <li>
                          <BiLike onClick={increaseYourLike} />
                          <span className="like-comment">
                            {AddAllLike.length}
                          </span>
                        </li>
                        <li>
                          <BiDislike onClick={decreaseYourLike} />
                          <span className="like-comment">
                          {AddAllLike.length}
                          </span>
                        </li>
                        <li>
                          <MdMessage />
                          <span className="like-comment">
                            {CommentListingAllData.length}
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="overlay-div-comment">
                      <hr className="hr-style" />
                      <div className="scrolling-comment">
                        <div className="comment-box-com">
                          <form className="form">
                            <div className="userImg">
                              <img
                                className="imgdefault"
                                // src={"https://app.soundchatradio.com/soundradiobackend/images/"}
                                // alt=""
                              />
                            </div>
                            <div className="emoji-input">
                              <input
                                type="text"
                                value={Value}
                                onChange={(e) => setValue(e.target.value)}
                                className="postComment"
                                placeholder="Type your reply here"
                              />
                            </div>
                            <span
                              type="submit"
                              onClick={addComment}
                              className="postBtn-div"
                            >
                              Post
                            </span>
                          </form>
                        </div>
                        {CommentListingAllData.map((element) => {
                          return (
                            <div key={element.comId}>
                              {/* <p>
                            {JSON.stringify(element.replies.length>0) ? <>{element.replies.length && element.replies.map((reply) =>{

                            })}</>:<></> } </p> */}
                              <div className="halfDiv-div" >
                                <div className="comment-list-img">
                                  <img
                                    className="imgdefault-div"
                                    src={
                                      "https://app.soundchatradio.com/soundradiobackend/images/" +
                                      element.profilepic
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="read-comment">
                                  <h5>
                                    {element.fullName}
                                    <span className="comment-date">
                                      {moment(element.createdAt).format(
                                        "ddd, ll"
                                      )}
                                    </span>
                                  </h5>
                                  <p>{element.text}</p>
                                </div>
                              </div>
                              <div className="reply-comment" >
                                <h5
                                  className="reply-text"
                                  ref={focusPoint}
                                  onClick={() => onClickReply(element.comId)}
                                  
                                >
                                  Reply    
                                   {(element.replies.length>0)?<><span className="reply-count-div">{element.replies.length}&nbsp;replies</span></>:<><div style={{display:"none"}}></div></> }
                                </h5>
                               
                                {(showText === element.comId && ButtonClickShow )? (
                                  <>
                                    <div className="reply-comment-box">
                                      <div className="listing-reply-img">
                                        <img
                                        // src={"https://app.soundchatradio.com/soundradiobackend/images/"}
                                        // alt=""
                                        />
                                      </div>
                                      <div className="emoji-input-reply">
                                        <input
                                          type="text"
                                          value={ReplyValue}
                                          onChange={(e) =>
                                            setReplyValue(e.target.value)
                                          }
                                          className="postCommentreply"
                                          placeholder="Add a reply"
                                        />
                                      </div>
                                      <span
                                        type="submit"
                                        onClick={() => {
                                          replyComment(element.comId);
                                        }}
                                        className="postBtn-reply"
                                      >
                                        Post
                                      </span>
                                    </div>
                                    {/* <p> */}

                                    {element.replies.length > 0 ? (
                                      <>
                                        {element.replies.map((reply) => {
                                          return (
                                            
                                              <div key={reply.id} className="listing-reply-comment">
                                                <div className="listing-reply-img">
                                                  <img
                                                    src={
                                                      "https://app.soundchatradio.com/soundradiobackend/images/" +
                                                      reply.user.profilepic
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="read-replycomment">
                                                  <h5>
                                                    {reply.user.fname}{" "}
                                                    <span className="reply-date">
                                                      {moment(
                                                        reply.createdAt
                                                      ).format("ddd, ll")}
                                                    </span>{" "}
                                                  </h5>
                                                  <p>{reply.reply}</p>
                                                </div>
                                              </div>
                                            
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {/* </p> */}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
      <FooterSection />
    </>
  );
}

// id={"soundchat" + index}
