import React, { useState, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { BsFillChatDotsFill } from "react-icons/bs";
import chatIcon from "../asstes/icon/chat.png"
export default function ChatIcon() {
  // console.log("props---", props)
  const pathlocation = useLocation();
  const [RemoveIcon, setRemoveIcon] = useState(true);

  console.log(pathlocation);
  useEffect(() => {
    if (
      pathlocation.pathname === "/Login" ||
      pathlocation.pathname === "/Register" ||
      pathlocation.pathname === "/Chatting"
    ) {
      setRemoveIcon(false);
    } else {
      setRemoveIcon(true);
    }
  }, [pathlocation]);
  console.log(RemoveIcon, "remove");

  return (
    <>
      {RemoveIcon === true ? (
        <>
        <a href="/Chatting" target="_blank">
          {/* <BsFillChatDotsFill className="chaticon-mobile"></BsFillChatDotsFill> */}
          <div className="chaticon-mobile">
            <img src={chatIcon} alt="" />
          </div>
          </a>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
