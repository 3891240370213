import React, { useState, useEffect,useRef } from "react";
// import ChattingLogin from "./ChattingLogin";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import moment from "moment-timezone";
import { BsFillCursorFill, BsEmojiSmileFill } from "react-icons/bs";
import { FiSend,FiBold, FiItalic, FiUnderline, FiPower} from "react-icons/fi";
import { AiOutlinePoweroff, AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ColorPicker from "../compontes/ColorPicker";
import TextColorChat from "../compontes/TextColorChat";
import socketIO from "socket.io-client";
function ChattingLogin() {
  const navigate = useNavigate()
  const el = useRef(null);
  const socket = socketIO.connect(`https://chat.soundchatradio.com:8000/`);
  const color = JSON.parse(localStorage.getItem("color"));
  const textColorData = JSON.parse(localStorage.getItem("textcolor"));
  const userId = localStorage.getItem("id");
  const username = JSON.parse(localStorage.getItem("username"));
  const token = localStorage.getItem("accessToken");
  const time = moment().tz("America/Detroit");
  const [ChatMessage, setChatMessage] = useState([]);
  const [value, setValue] = useState("");
  const [messageDataObj, setmessageDataObj] = useState({});
  const [underline, setUnderline] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [TextColor, setTextColor] = useState("");
  const [BackgroundColor, setBackgroundColor] = useState("");
  const [RightData, setRightData] = useState([]);
  useEffect(() => {
    // setBackgroundColor(color.hex);
    // setTextColor(textColorData.hex);
    setBackgroundColor(color);
    setTextColor(textColorData);
  }, [localStorage.getItem("color"), localStorage.getItem("textcolor")]);
  useEffect(() => {
    el.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'nearest' });
});
  useEffect(() => {
    setmessageDataObj({
      bold: bold ? 1 : 0,
      italic: italic ? 1 : 0,
      underline: underline ? 1 : 0,
      messageBubbleColor: BackgroundColor,
      textColor: TextColor,
      message: value,
    });
  }, [
    value,
    underline,
    bold,
    italic,
    TextColor,
    BackgroundColor,
    localStorage.getItem("color"),
    localStorage.getItem("textcolor"),
  ]);

  useEffect(() => {
    // trackPromise(
    axios
      .get(`https://chat.soundchatradio.com:8000/group_chat_history`)
      .then((response) => {
        setChatMessage(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // );
  }, []);

  function boldFunc() {
    if (bold === false) {
      setBold(true);
    } else {
      setBold(false);
    }
  }

  function italicFunc() {
    if (italic === false) {
      setItalic(true);
    } else {
      setItalic(false);
    }
  }
  function underlineFunc() {
    if (underline === false) {
      setUnderline(true);
    } else {
      setUnderline(false);
    }
  }
  function handleSendMessage(e) {
    e.preventDefault();
    console.log(
      {
        id: 0,
        status: 0,
        name: username,
        roomId: 123,
        senderId: userId,
        time: time,
        bold: bold ? 1 : 0,
        italic: italic ? 1 : 0,
        underline: underline ? 1 : 0,
        messageBubbleColor: BackgroundColor,
        textColor: TextColor,
        message: value,
        messageType: "text",
      },
      "object"
    );
    if (value.trim() && username) {
      console.log(username, "sdhgcj");
      socket.emit("send_message", {
        id: 0,
        status: 0,
        name: username,
        roomId: 123,
        senderId: userId,
        time: time,
        bold: bold ? 1 : 0,
        italic: italic ? 1 : 0,
        underline: underline ? 1 : 0,
        messageBubbleColor: BackgroundColor,
        textColor: TextColor,
        message: value,
        messageType: "text",
      });
      setValue("");
    }
  }
  let temp = [];
  useEffect(() => {
    socket.on("receive_message", (data) => {
      temp.push(data);
      setRightData(temp);
    });
  }, [socket]);
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  function logoutchat() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    // props.props()
    showToastMessage("Logout Sccessfully");
  navigate("/",{replace:true,logoutstatus:"true"});
  }
  return (
    <>
    
     {/* <div className="chatting-new-window-newchat">
        <div className="container">
          <Row>
            <Col lg={8} md={12} sm={12} className="offset-2-newchat"> */}
              <section className="msger-newchat">
                <header className="msger-header-newchat">
                  <div className="msger-header-title-newchat">
                  <h6 className="chatting-chatroom"> Chatroom</h6> 
                    <h6 className="logoutchat-chatting " onClick={() => {logoutchat()}}>
                <FiPower />
              </h6>
                  </div>
                  <div className="msger-header-options-newchat">
                    <span>
                      <i className="fas fa-cog"></i>
                    </span>
                  </div>
                </header>
                <main className="msger-chat-newchat">
                  {ChatMessage.map((chat) => {
                    return (
                      <>
                        <div className="msg-newchat left-msg-newchat">
                          <div
                            className="msg-bubble-newchat"
                            style={{
                              backgroundColor:
                                "#" + chat.messageBubbleColor.slice(4),
                            }}
                          >
                            <div className="msg-info-newchat">
                              <div
                                className="msg-info-name-newchat"
                                style={{ color: "yellow" }}
                              >
                                {" "}
                                {chat.name}
                              </div>
                            </div>

                            <div
                              className="msg-text-newchat"
                              style={{
                                color: "#" + chat.textColor.slice(4),
                                fontWeight: chat.bold ? "bold" : "",
                                fontStyle: chat.italic ? "italic" : "",
                                textDecorationLine: chat.underline
                                  ? "underline"
                                  : "",
                              }}
                            >
                              {chat.messageType === "text" ? (
                                <> {chat.message}</>
                              ) : (
                                <>
                                  <img
                                    src={chat.message}
                                    alt="my-gif"
                                    className="gif-text"
                                  />
                                </>
                              )}
                            </div>
                            <div className="msg-info-time-newchat">
                              {moment(chat.createdAt).add(1,'days').add(-2,'hours').add(1, 'minutes')
                                .tz("America/Detroit")
                                .format("MMM D h:mm")}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {RightData.map((element) => {
                    return (
                      <>
                        <div className="msg-newchat left-msg-newchat">
                          <div
                            className="msg-bubble-newchat"
                            style={{
                              backgroundColor:
                                "#" + element.messageBubbleColor.slice(4),
                            }}
                          >
                            <div className="msg-info-newchat">
                              <div
                                className="msg-info-name-newchat"
                                style={{ color: "yellow" }}
                              >
                                {element.name}
                              </div>
                            </div>

                            <div
                              className="msg-text-newchat"
                              style={{
                                color: "#" + element.textColor.slice(4),
                                fontWeight: element.bold ? "bold" : "",
                                fontStyle: element.italic ? "italic" : "",
                                textDecorationLine: element.underline
                                  ? "underline"
                                  : "",
                              }}
                            >
                              {element.messageType === "text" ? (
                                <> {element.message}</>
                              ) : (
                                <>
                                  <img
                                    src={element.message}
                                    alt="my-gif"
                                    className="gif-text"
                                  />
                                </>
                              )}
                            </div>
                            <div className="msg-info-time-newchat">
                              {moment(element.createdAt)
                                .tz("America/Detroit")
                                .format("MMM D h:mm")}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* <div className="msg-newchat right-msg-newchat">
                    <div
                      className="msg-bubble-newchat"
                      style={{
                        backgroundColor: messageDataObj.messageBubbleColor,
                      }}
                    >
                      <div className="msg-info-newchat">
                        <div
                          className="msg-info-name-newchat"
                          style={{ color: "yellow" }}
                        >
                          {username}
                        </div>
                      </div>

                      <div
                        className="msg-text-newchat"
                        style={{
                          color: messageDataObj.textColor,
                          fontWeight: messageDataObj.bold ? "bold" : "",
                          fontStyle: messageDataObj.italic ? "italic" : "",
                          textDecorationLine: messageDataObj.underline
                            ? "underline"
                            : "",
                        }}
                      >
                        {messageDataObj.message}
                       
                      </div>
                      <div className="msg-info-time-newchat">12:46</div>
                    </div>
                  </div> */}

                 <div ref={el}></div>
                </main>

                {/* <div className="chat-fun-newchat">
                  <ul>
                    <li className="chat-input-icon-newchat">
                      <ColorPicker />
                    </li>
                    <li className="chat-input-icon-newchat">
                      <TextColorChat />
                    </li>
                    <li
                      onClick={() => boldFunc()}
                      className="chat-input-icon-newchat"
                    >
                      <ImBold />
                    </li>
                    <li
                      onClick={() => italicFunc()}
                      className="chat-input-icon-newchat"
                    >
                      <ImItalic />
                    </li>
                    <li
                      onClick={() => underlineFunc()}
                      className="chat-input-icon-newchat"
                    >
                      <ImUnderline />
                    </li>
                  </ul>
                </div> */}
                <footer
                  className="msg-chatt-footer-newchat"
                  onSubmit={handleSendMessage}
                >
                   <div className="chat-fun-newchat">
                  <ul>
                    <li className="chat-input-icon-newchat">
                      <ColorPicker />
                    </li>
                    <li className="chat-input-icon-newchat">
                      <TextColorChat />
                    </li>
                    <li
                      onClick={() => boldFunc()}  style={(bold)? {color:'#fff'}:{}}
                      className="chat-input-icon-newchat"
                    >
                      <FiBold />
                    </li>
                    <li
                      onClick={() => italicFunc()} style={(italic)? {color:'#fff'}:{}}
                      className="chat-input-icon-newchat"
                    >
                      <FiItalic />
                    </li>
                    <li
                      onClick={() => underlineFunc()} style={(underline)? {color:'#fff'}:{}}
                      className="chat-input-icon-newchat"
                    >
                      <FiUnderline />
                    </li>
                  </ul>
                </div>
                <div className="footer-input-div">
                  <input
                    value={value}
                    type="text"
                    className="msg-chatt-text-newchat"
                    placeholder="Write a message..."
                    autoComplete="off"
                    style={{ color: "#000 !importants" }}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <button
                    className="btn btn--send-newchat"
                    title="Send message"
                    // tabindex="0"
                  >
                    {/* <span className="emoji-newchat">
                      <BsEmojiSmileFill />
                    </span> */}
                    <h5 className="send-bn-newchat">
                      <FiSend />
                    </h5>
                  </button>
                  </div>
                </footer>
              </section>
            {/* </Col>
          </Row>
        </div>
      </div>  */}
    
    
     
    </>
  );
}
export default ChattingLogin;



