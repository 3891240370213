import React from "react";
import Home from "../../pages/Home";

export default function App() {
  return (
    <div>
      <Home />
    </div>
  );
}
