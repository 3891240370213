import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment-timezone";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import ChatComponents from "../../pages/ChatComponents";
import ChatLoginGuest from "../../pages/ChatLoginGuest";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";
import { HiUser } from "react-icons/hi";
import axios from "axios";
import { NavLink , useNavigate} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../asstes/chat.css"
// import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { toast } from "react-toastify";
import { Troubleshoot, TroubleshootOutlined } from "@mui/icons-material";
export default function SoundchatVideoSection(props) {
  
  const day = moment().tz("America/Detroit").format("dddd");
  const time = moment().tz("America/Detroit").format("HH:mm");
  const [LiveAudio, setLiveAudio] = useState([]);
  const [LiveShowsData, setLiveShowsData] = useState([]);
  const [waveClass, setwaveClass] = useState("box");
  const [audioStatus, setaudioStatus] = useState(false);
  const [ShowTimeData, setShowTimeData] = useState([]);
  const [LiveShow, setLiveShow] = useState([]);

  // console.log(props.props("rashmilodhi"))

  useEffect(() => {
    // trackPromise(
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/listlivecontent`)
      .then((response) => {
        setLiveAudio(response.data.data[1]);
      })
      .catch((error) => {
        console.log(error);
      });
    // );
  }, []);
  useEffect(() => {
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/listshowschedule`)
      .then((response) => {
        setLiveShow(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    for (let i = 0; i < LiveShow.length; i++) {
      if (LiveShow[i].post_title === day) {
        setLiveShowsData(LiveShow[i].scheduleperdays);
        for (let j = 0; j < LiveShow[i].scheduleperdays.length; j++) {
          let starttime =
            LiveShow[i].scheduleperdays[j].show_start_date.split(":");
          let endtime = LiveShow[i].scheduleperdays[j].show_end_date.split(":");
          let currenttime = time.split(":");
          // console.log(starttime[0], endtime[0], currenttime[0], time, "sdghfg");
          if (
            parseInt(starttime[0]) <= parseInt(currenttime[0]) &&
            parseInt(endtime[0]) >= parseInt(currenttime[0])
          ) {
            //console.log("okay");
            setShowTimeData({
              show_name: LiveShow[i].scheduleperdays[j].show_name,
              show_audio_url: LiveShow[i].scheduleperdays[j].show_audio_url,
              show_image: LiveShow[i].scheduleperdays[j].show_image,
              show_start_date: LiveShow[i].scheduleperdays[j].show_start_date,
              show_end_date: LiveShow[i].scheduleperdays[j].show_end_date,
            });
          }
        }
      }
    }
  }, [LiveShow]);

  // console.log("props soundchat", props )
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [LiveVedio, setLiveVedio] = useState({});
  const [videoStatus, setvideoStatus] = useState(true);
  const [guestlogin, setguestlogin] = useState(
    localStorage.getItem("guestlogin")
  );
  useEffect(() => {
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/listlivecontent`)
      .then((response) => {
        setLiveVedio(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
        
  	}, [screenSize])
  useEffect(() => {
    if(screenSize.width<768){
      AudioPlayer.autoPlay=false
      setaudioStatus(false);
      setvideoStatus(false);
    }else{
      if (props.props === "play") {
        setvideoStatus(false);
        setaudioStatus(true);

      } else if (props.props === "pause") {
        // setvideoStatus(true);
        setaudioStatus(false);

      }
    }
    

    
  }, [props,screenSize]);

  function handleSubmit(values) {
    axios({
      method: "POST",
      url: "https://app.soundchatradio.com:3000/api/v1/auth/guestsignin",
      data: {
        username: values.username,
      },
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === 400) {
          showToastMessageone(response.data.message);
        } else {
          showToastMessage("Login Guests Login Successfully ");
          setguestlogin(response.data.data.username);
          localStorage.setItem(
            "guestlogin",
            JSON.stringify(response.data.data.username)
          );
          navigate("/",{replace:true});
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });

    console.log(screenSize,audioStatus,videoStatus,props,'size')
    
  // const audioplayer = ()=>{
    
  //   return <><h1>kkk</h1></>
  // }




  
  return (
    <section className="bg-color">
      <div className="Soundchat-div">
        <div className="container-fluid">
          <Row>
            <Col xs={12} md={6} lg={9}>
            <div className="mobile-view-button-audio-video">
                <Tabs
                  defaultActiveKey="Watch Live"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Watch Live" title="Watch Live">
                  <div className="video-soundchat">
                <div className="video-live-home">
                  <ReactPlayer
                    className="react-player"
                    url={LiveVedio.livelink}
                    width="100%"
                    height="430px"
                    volume={1}
                    auto="true"
                     playing={videoStatus}
                    controls={true}
                    onPlay={() => {
                      console.log("onPlay")
                      setvideoStatus(true)
                    setaudioStatus(false)
                      localStorage.setItem("videoplayer", true);
                      localStorage.setItem("audioplayer", false);
                    }}
                    
                    onPause={() => {
                      console.log("onPause")
                      setvideoStatus(false)
                    setaudioStatus(true)
                      localStorage.setItem("videoplayer", false);
                      // localStorage.setItem("audioplayer", true);
                    }}
                  />
                </div>
              </div>
                  </Tab>
                  <Tab eventKey="Listen Live" title="Listen Live" >
                  <div className="video-soundchat">
            <div className="logo-soundchat-mobile-audio">
              <img
                src={
                  "https://app.soundchatradio.com/soundradiobackend/images/podcast/" +
                  ShowTimeData.show_image
                }
                alt="img-error"
              />
            </div>
            <div className="logo-text-heading-mobile-audio">
              <h6 className="header-new-bottom-new-mobile-view">
                {ShowTimeData.show_name}
              </h6>
              <div className="top-head-section-mobile">
                <p>{day} 
                <span className="todu-mobile">
                  {ShowTimeData.show_start_date}-{ShowTimeData.show_end_date}
                </span>
                </p>
              </div>
              {(screenSize.width>767)?<></>:<>
              <div className="main-audio-live-content">
                <AudioPlayer
                  src={LiveAudio.livelink}
                  autoPlay={Boolean(false)}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  showJumpControls={false}
                  showFilledProgress={false}
                  onPause={(e) => {
                    console.log("onPlay");
                    setvideoStatus(true)
                    setaudioStatus(false)
                    localStorage.setItem("audioplayer", false);
                    // localStorage.setItem("videoplayer", true);
                    if (waveClass === "box active") {
                      setwaveClass("box");
                    }
                  }}
                  onPlay={(e) => {
                    setvideoStatus(false)
                    setaudioStatus(true)
                    localStorage.setItem("audioplayer", true);
                    localStorage.setItem("videoplayer", false);

                    console.log("onPlay");
                    if (waveClass === "box") {
                      setwaveClass("box active");
                    }
                  }}
                />
                <div className="box-container flex">
                  <div className={`${waveClass} box1`}></div>
                  <div className={`${waveClass} box2`}></div>
                  <div className={`${waveClass} box3`}></div>
                  <div className={`${waveClass} box4`}></div>
                  <div className={`${waveClass} box5`}></div>
                  <div className={`${waveClass} box6`}></div>
                  <div className={`${waveClass} box7`}></div>
                  <div className={`${waveClass} box2`}></div>
                  <div className={`${waveClass} box3`}></div>
                  <div className={`${waveClass} box4`}></div>
                  <div className={`${waveClass} box5`}></div>
                  <div className={`${waveClass} box6`}></div>
                  <div className={`${waveClass} box7`}></div>
                </div>
              </div>
              </>}
            </div>
            
        </div>
            </Tab>
             
                 
                </Tabs>
                </div>

              {/* <div className="video-soundchat">
                <div className="video-live-home">
                  <ReactPlayer
                    className="react-player"
                    url={LiveVedio.livelink}
                    width="100%"
                    height="430px"
                    volume={1}
                    auto="true"
                     playing={videoStatus}
                    controls={true}
                    onPlay={() => {
                      console.log("onPlay")
                      localStorage.setItem("videoplayer", true);
                      localStorage.setItem("audioplayer", false);
                    }}
                    
                    onPause={() => {
                      console.log("onPause")
                      localStorage.setItem("videoplayer", false);
                      localStorage.setItem("audioplayer", true);
                    }}
                  />
                </div>
              </div> */}
            </Col>

            <Col xs={12} md={6} lg={3}>
              <div className={(token || guestlogin) ? "chat-secound":"chat-user"} >
                {/* {token && guestlogin? <div className=""></div>:<></> } */}
                {!token ? (
                  <>
                    {guestlogin === undefined ||
                    guestlogin === "" ||
                    guestlogin === null ? (
                      <>
                        <NavLink to="/Login">
                          <button className="btn-div-chat-login">
                            Login to chat
                          </button>
                        </NavLink>
                        <br /> <br />
                        <p className="div-or">or</p>
                      </>
                    ) : (
                      <></>
                    )}

                    {guestlogin === undefined ||
                    guestlogin === "" ||
                    guestlogin === null ? (
                      <>
                        <form
                          className="form-group"
                          onSubmit={formik.handleSubmit}
                        >
                          <div className="form-group has-search">
                            <span className="user-icon-div">
                              <HiUser />
                            </span>
                            <input
                              placeholder="Enter Guest Username"
                              type="text"
                              name="username"
                              autoComplete="off"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.username &&
                              formik.touched.username && (
                                <p className="errormessage mt-3">
                                  {formik.errors.username}
                                </p>
                              )}
                            <br />
                            <button
                              type="submit"
                              className="btn-div-chat-login new-user-chat-010"
                            >
                              Login as Guest
                            </button>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <ChatLoginGuest
                          props={() => {
                            setguestlogin(localStorage.getItem("guestlogin"));
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <ChatComponents />
                  </>
                )}
              </div>


              {/* {(!localStorage.getItem("guestlogin")===undefined) || (localStorage.getItem("guestlogin")==="")? 
                 (<>
                      
                      <div className="chat-user">
                    
                    <NavLink to="/Login">
                      <button className="btn-div-chat-login">
                        Login to chat
                      </button>
                    </NavLink>
                    <br /> <br />
                    <p className="div-or">or</p>
                      <form className="form-group" onSubmit={formik.handleSubmit}>
                      <div className="form-group has-search">
                        <span className="user-icon-div">
                          <HiUser />
                        </span>
                        <input
                          placeholder="Enter Guest User"
                          type="text"
                          name="username"
                          autoComplete="off"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.username && formik.touched.username && (
                          <p className="errormessage mt-3">
                            {formik.errors.username}
                          </p>
                        )}
                        <br />
                        <button
                          type="submit"
                          className="btn-div-chat-login new-user-chat-010"
                        >
                          Login as Guest
                        </button>
                      </div>
                    </form>      
                  </div>
                 </>)
                 :
                 (<><ChatLoginGuest/></>)} */}
                

            </Col>
          </Row>
        </div>
      </div>
      {/* <audioplayer/> */}
    </section>
  );
}

// &&  ((!localStorage.getItem("guestlogin")==="") || (localStorage.getItem("guestlogin")===undefined)))
// </>):(<><ChatLoginGuest/></>)}
